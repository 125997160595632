import {useHistory, useParams} from "react-router";
import {useFetchedProductsState, useGlobalStates} from "./GlobalState";
import {useState} from "react";
import {useQuery} from "./utils/useQuery";


const ExpandedCard = () => {

    const history = useHistory()
    const {addToBasket} = useGlobalStates()
    const [count, setAmount] = useState(1);
    const [selectedExtras, setSelectedExtras] = useState([]);
    const [notiz, setNotiz] = useState("");
    const {products} = useFetchedProductsState();
    let {id} = useParams();
    const card = products.find(products => products.id === id)
    let query = useQuery();

    const restID = query.get("rest_id")
    const goBack = () => history.goBack()

    !card && goBack()

    const StoreItem = () => {
        addToBasket({
            ...card,
            count: count,
            selectedExtras: selectedExtras,
            notiz: notiz,
            extrasAufpreis: calculateExtrasPrice()
        }, restID)
        goBack()
    }


    const calculateMaxPrice = () => {
        if (!card) return 0

        const priceAmount = card.price * count
        const priceExtras = calculateExtrasPrice()
        return priceAmount + priceExtras
    }

    const calculateExtrasPrice = () => selectedExtras.length === 0 ? 0 : selectedExtras.reduce((accumulator, extra) => extra?.aufpreis + accumulator, 0)


    const toggleExtra = (extra) => {

        const indexOfExtra = selectedExtras.indexOf(extra)

        if (indexOfExtra > -1) {
            const filteredExtras = selectedExtras.filter((selectedExtra) => selectedExtra.name !== extra.name)

            setSelectedExtras(filteredExtras)
        } else {
            setSelectedExtras([...selectedExtras, extra])
        }


    }


    return (

        <div>
            <div className="inset-0 bg-gray-900 transition-all opacity-60 z-30 fixed">
            </div>


            <div
                className={`max-h-screen overflow-y-scroll touchScroll inset-x-0 top-0 bg-white fixed z-50  min-h-screen visibleStoli`}
            >
                <div>
                    <div className="relative flex sticky top-0 z-30">
                        <div className="absolute top-3 right-3 bg-white rounded-full "
                             onClick={goBack}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </div>
                    </div>
                    <div
                        className="w-full h-40 flex-shrink-0 mr-3 object-cover relative overflow-hidden">
                        {card?.img && <img
                            className="object-cover h-full w-full"
                            src={card.img}
                            alt=""
                        />}
                    </div>

                    <div className="w-full flex-1 p-3">
                        <h1 className="font-semibold mb-1">{card?.name}
                        </h1>
                        <p className="text-gray-500 text-sm mb-2">{card?.description}</p>
                        <p className="flex font-semibold">
                            💰 {card?.price.toFixed(2).split(".").join(",")}€
                        </p>

                        <br/>
                        <div>
                            {
                                card?.extras?.map((extra, index) => <div
                                    key={index}>
                                    <div className="flex justify-between items-center border-b">
                                        <input onClick={() => toggleExtra(extra)}
                                               className="mr-3" type="checkbox" id={index} name={extra.name}/>
                                        <label className="font-bold w-full flex justify-between items-center h-10"
                                               htmlFor={index}>{extra.name}
                                            <p>€ {extra?.aufpreis?.toFixed(2).split(".").join(",") || "0"}</p>
                                        </label>
                                    </div>

                                </div>)
                            }
                        </div>
                        <br/>
                        <textarea value={notiz} onChange={(e) => setNotiz(e.target.value)} className="w-full border p-3"
                                  placeholder="Notiz: z.B. Bitte ohne Zwiebeln" rows="4"
                                  cols="50"/>
                    </div>
                </div>
                <br/>


                <div className="flex p-3 justify-between ">
                    <div
                        className="bg-gray-100 rounded-2xl w-32 h-12 mr-2 flex items-center justify-evenly overflow-hidden">
                        <button onClick={() => count > 1 && setAmount(count - 1)}
                                className="w-full h-full  flex justify-center items-center focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M20 12H4"/>
                            </svg>
                        </button>
                        <h1 className="text-center w-20">{count}</h1>
                        <button onClick={() => setAmount(count + 1)}
                                className="w-full h-full  flex justify-center items-center  focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                            </svg>
                        </button>
                    </div>
                    <button
                        onClick={StoreItem}
                        className="text-sm bg-black rounded-2xl h-12 flex-1 flex text-white items-center justify-between px-4 focus:outline-none">
                        <h1 className="mr-1">Hinzufügen</h1>
                        <p>€ {calculateMaxPrice().toFixed(2).split(".").join(",")}</p>
                    </button>
                    <br/>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        </div>
    )
}

export default ExpandedCard
