import {useState} from "react";
import {getBasketTotal, useGlobalStates} from "./GlobalState";
import {placeOrder} from "./firebase_businesslogic";
import {useLocation} from "react-router-dom";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const ShoppingCard = ({history}) => {

    const {
        basket,
        personalData,
        setPersonalData,
        removeItem,
        addToBasket,
        decOrRemoveItem,
        emptyBasket
    } = useGlobalStates()
    const [tischNummer, setTischNummer] = useState("");
    let query = useQuery();
    const restID = query.get("rest_id")

    const basketForRestaurant = basket[restID] ?? []

console.log({basketForRestaurant})

    const PlaceOrder = async (e) => {
        e.preventDefault()
        const orderObject = {
            items: basketForRestaurant,
            personalData,
            tischNummer,
            summe: getBasketTotal(basketForRestaurant)
        }
        const err = await placeOrder(orderObject, restID)
        if (err) {
            alert(err)
            return
        }

        emptyBasket(restID)
        history.push("/succ_order?rest_id="+restID)


    }

    return (
        <div className="">
            <div
                className={`max-h-screen overflow-y-scroll touchScroll top-0 min-h-screen inset-x-0 bg-white fixed z-50 p-4 z-50 visibleStoli`}
            >

                <div className="relative flex bg-white sticky top-0">
                    <div className="absolute top-0 right-0 bg-white rounded-full  "
                         onClick={() => history.push("/?rest_id="+restID)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </div>
                </div>

                <div className=" ">

                    <br/>
                    <h1 className="font-semibold text-2xl">Die Bestellung wird direkt an dein Tisch gebracht.
                        ♨️</h1>
                    <br/>

                    {
                        basketForRestaurant.map((basketItem, index) => < ShoppingCardItem key={index}
                                                                             onIncrease={(item) => addToBasket(item, restID)}
                                                                             onDecrease={(item) => decOrRemoveItem(item, restID)}
                                                                             onDeleteClicked={(item) => removeItem(item, restID)}
                                                                             item={basketItem}/>)
                    }

                </div>
                <br/>
                <form onSubmit={PlaceOrder}>
                    <div className="mb-4">
                        <h1 className="text-2xl font-semibold">Persönliche Daten 📌</h1>
                        <p className="text-gray-400">Corona bedingt blabla und blabla</p>
                        <br/>
                        <input placeholder="Name" required={true} value={personalData.name}
                               onChange={(e) => setPersonalData({name: e.target.value})}
                               className="w-full bg-gray-100 rounded-2xl p-3 mb-2 focus:outline-none"
                               type="text"/>
                        <input placeholder="Email" required={true} value={personalData.email}
                               onChange={(e) => setPersonalData({email: e.target.value})}
                               className="w-full bg-gray-100 rounded-2xl p-3 mb-2 focus:outline-none"
                               type="text"/>
                        <input placeholder="Telefon" required={true} value={personalData.tel}
                               onChange={(e) => setPersonalData({tel: e.target.value})} type="number" pattern="[0-9]*"
                               className="w-full bg-gray-100 rounded-2xl p-3 mb-2 focus:outline-none"/>
                        <input value={tischNummer} required={true} onChange={(e) => setTischNummer(e.target.value)}
                               placeholder="Tisch Nummer" type="text" pattern="[0-9]*"
                               className=" w-full bg-gray-100 rounded-2xl p-3 mb-2 focus:outline-none"/>
                    </div>


                    <div className="flex justify-between ">
                        <button
                            type="submit"
                            className="bg-black rounded-2xl h-14 flex-1 flex text-white items-center justify-between px-4 focus:outline-none">
                            <h1 className="mr-1">Bestellen</h1>
                            <p>€ {getBasketTotal(basketForRestaurant).toFixed(2).split(".").join(",")}</p>
                        </button>
                    </div>
                </form>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>


            </div>
        </div>

    )
}


const ShoppingCardItem = ({item, onDeleteClicked, onIncrease, onDecrease}) => {
    return (


        <div className="flex py-3 border-b mb-3">
            <div className="rounded-full overflow-hidden h-10 w-10 flex-shrink-0 mr-3 object-cover">
                {item.img && <img
                    className="object-cover h-full w-full"
                    src={item.img}
                    alt=""/>}
            </div>

            <div className="flex-1 px-2">
                <div className="flex justify-between">
                    <h1 className="mb-2">{item.name}</h1>
                    <h1>€ {(item.price * item.count).toFixed(2).split(".").join(",")}</h1>
                </div>


                <div>
                    {
                        item.selectedExtras?.map((selectedExtra, index) => <div
                            key={index}
                            className="ml-3 text-sm text-gray-600 flex justify-between">+ {selectedExtra.name}
                            <span>€ {selectedExtra.aufpreis.toFixed(2).split(".").join(",")}</span></div>)
                    }
                </div>
                <div className="text-gray-600 text-sm ml-3">
                    {item.notiz}
                </div>
                <br/>


                <div
                    className="bg-gray-100 rounded-2xl w-32 h-12 mr-2 flex items-center justify-evenly overflow-hidden">
                    <button onClick={() => onDecrease(item)}
                            className="w-full h-full  flex justify-center items-center focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M20 12H4"/>
                        </svg>
                    </button>
                    <h1 className="text-center w-20">{item.count}</h1>
                    <button onClick={() => onIncrease(item)}
                            className="w-full h-full  flex justify-center items-center  focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="">

                {/*     <button onClick={() => onDeleteClicked(item)} className="focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6  focus:outline-none" fill="none"
                         viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                    </svg>
                </button>*/}

            </div>

        </div>
    )
}

export default ShoppingCard
