const ListFilter = ({categories, onFilterChanged, selectedFilter}) => {

    if (!categories) {
        return <h1>...</h1>
    }


    return (
        < div

            className="flex  items-center overflow-x-scroll pb-3">
            < div
                onClick={() => onFilterChanged("All")}
                className={`${selectedFilter === "All" ? "bg-black text-white" : "orangeBG text-gray-900"}  h-12 px-3  rounded-2xl flex justify-center items-center inline-block mr-3`}>
                Alle
            < /div>
            {
                categories.map((filter, index) => < div
                    key={index}
                    onClick={() => onFilterChanged(filter.name)}
                    className={`${selectedFilter === filter.name ? "bg-black text-white" : "orangeBG text-gray-900"}  h-12 px-3  rounded-2xl flex justify-center items-center inline-block mr-3`}>
                    {filter.name}
                < /div>)
            }

        </div>)
}

export default ListFilter
