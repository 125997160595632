import SuccImg from "./undraw_Confirmed_re_sef7.svg"
import {useHistory} from "react-router-dom"
import {useQuery} from "./utils/useQuery";

const SuccPage = () => {
    const history = useHistory()
    let query = useQuery();

    const restID = query.get("rest_id")
    return (
        <div className="absolute inset-0 bg-white z-50 flex flex-col justify-center items-center p-4">
            <h1 className="text-2xl font-bold">Bestellung Erfolgreich</h1>
            <br/>
            <img className="w-32" src={SuccImg} alt=""/>
            <br/>
            <p className="text-gray-500">Der Kellner hat Ihre Bestellung erhalten.</p>
            <br/>
            <br/>
            <button onClick={() => history.push("/?rest_id=" + restID)}
                    className="bg-black text-white p-3 rounded-2xl focus:outline-none">Zurück zur Speisekarte
            </button>
        </div>
    )
}
export default SuccPage
