import CardList from "./CardList"
import {fetchStammdaten, useProducts} from "./firebase_businesslogic";
import ListFilter from "./ListFilter";
import {useEffect, useState} from "react";
import {useQuery} from "./utils/useQuery";


function App({match, history}) {
    let query = useQuery();

    const restID = query.get("rest_id")
    const [products, categories] = useProducts(restID)
    const [selectedFilter, setSelectedFilter] = useState("All");
    const [Stammdaten, setStammdaten] = useState();
    const [height, setHeight] = useState();

    const fetchStammdatenAsnyc = async () => {
        const stammdaten = await fetchStammdaten(restID)
        setStammdaten(stammdaten)
    }

    useEffect(() => {
        fetchStammdatenAsnyc()
    }, []);


    useEffect(() => {
        setHeight(window.innerHeight)
    }, []);




    const filteredProducts = selectedFilter === "All" ? products : products.filter((product) => product.category === selectedFilter)

    return (
        <div id="mainView" className="p-4 overflow-y-scroll touchScroll" style={{height: height || window.innerHeight}}>

            <div onClick={() => history.push("/pay?rest_id=" + restID)}
                 className="fixed bg-black text-white transition-all  p-4 flex z-30 bottom-4 right-4 rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                          d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"/>
                </svg>
            </div>


            <div className="h-16 flex w-full justify-center items-center ">
                <div className="bg-black rounded-3xl px-5 py-2 text-white text-sm">
                    {Stammdaten?.name || "..."}
                </div>
            </div>
            <br/>
            <div className="text-2xl font-bold">
                Top Gerichte
            </div>
            <br/>
            <div className="snapScrollbar overflow-x-scroll flex flex-nowrap pb-4">
                <div
                    className="h-64 bg-blue-200 rounded-2xl overflow-hidden relative object-cover transitionfix scrollAlignCenter inline-block min-w-full mr-3">

                    <div className="absolute top-2 right-2 bg-black rounded-full text-white px-1 opacity-60">
                        1/3
                    </div>

                    <img
                        className="object-cover w-full h-full"
                        src="https://images.unsplash.com/photo-1493770348161-369560ae357d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80"
                        alt=""/>
                    <div
                        className="absolute bottom-0 inset-x-0  glass_morphism bg-blue-200 bg-opacity-90 p-4 flex justify-between">
                        <div>
                            <h1 className="text-white font-bold text-2xl">Pancaces</h1>
                            <p className="text-white">
                                Kartoschki maraschki
                            </p>
                        </div>
                        <h1 className="font-bold  text-2xl text-white">
                            7,99 €
                        </h1>
                    </div>
                </div>

                <div
                    className="h-64 bg-blue-200 rounded-2xl overflow-hidden relative object-cover transitionfix scrollAlignCenter inline-block min-w-full mr-3">
                    <div className="absolute top-2 right-2 bg-black rounded-full text-white px-1 opacity-60">
                        2/3
                    </div>
                    <img
                        className="object-cover w-full h-full"
                        src="https://images.unsplash.com/photo-1528207776546-365bb710ee93?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80"
                        alt=""/>
                    <div
                        className="absolute bottom-0 inset-x-0  glass_morphism bg-blue-200 bg-opacity-90 p-4 flex justify-between">
                        <div>
                            <h1 className="text-white font-bold text-2xl">Pancaces</h1>
                            <p className="text-white">
                                Naverno Lecker
                            </p>
                        </div>
                        <h1 className="font-bold  text-2xl text-white">
                            3,99 €
                        </h1>
                    </div>
                </div>

                <div
                    className="h-64 bg-blue-200 rounded-2xl overflow-hidden relative object-cover transitionfix scrollAlignCenter inline-block min-w-full mr-3">
                    <div className="absolute top-2 right-2 bg-black rounded-full text-white px-1 opacity-60">
                        3/3
                    </div>
                    <img
                        className="object-cover w-full h-full"
                        src="https://images.unsplash.com/photo-1604382354936-07c5d9983bd3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80"
                        alt=""/>
                    <div
                        className="absolute bottom-0 inset-x-0  glass_morphism bg-blue-200 bg-opacity-90 p-4 flex justify-between">
                        <div>
                            <h1 className="text-white font-bold text-2xl">Pizza</h1>
                            <p className="text-white">
                                Maraschki mit Sucuk
                            </p>
                        </div>
                        <h1 className="font-bold  text-2xl text-white">
                            8,40 €
                        </h1>
                    </div>
                </div>
            </div>
            <div className="flex justify-center mt-2">
                <div className="w-2 h-2 rounded-full bg-gray-700 mr-1 flex-shrink-0">
                </div>
                <div className="w-2 h-2  rounded-full bg-gray-500  mr-1">
                </div>
                <div className="w-2 h-2  rounded-full bg-gray-300  mr-1">
                </div>
            </div>
            <br/>
            <ListFilter selectedFilter={selectedFilter} onFilterChanged={(category) => setSelectedFilter(category)}
                        categories={categories}/>
            <br/>
            <div>
                <CardList products={filteredProducts} match={match} history={history}/>
            </div>
            <div className="h-40">
            </div>
        </div>
    );
}

export default App;
