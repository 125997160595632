import create from 'zustand'
import {persist} from "zustand/middleware"

export const useGlobalStates = create(persist((set, get) => ({
        basket: {},
        personalData: {name: "", email: "", tel: ""},
        putIntoBasket: (newItem, restId) => set(state => ({basket: {[restId]: [...state.basket[restId], newItem]}})),
        setPersonalData: (entry) => set(state => ({personalData: {...state.personalData, ...entry}})),
        addToBasket: (item, restId) => AddOrIncreaseItemInBasket(item, get, set, restId),
        decOrRemoveItem: (item, restId) => RemoveOrDecreaseFromBasket(item, get, set, restId),
        removeItem: (item, restId) => RemoveItem(item, get, set, restId),
        emptyBasket: (restId) => set({basket: {[restId]: []}})
    }),
    {
        name: "safeat_storage", // unique name
    }
))

export const useFetchedProductsState = create(set => ({
        products: [],
        setProducts: (fetchedProducts) => set(state => ({products: fetchedProducts})),
    }),
)


export const getBasketTotal = (warenkorb) => {

    const amount = warenkorb?.reduce((commulate, item) => item.price * item.count + item.extrasAufpreis + commulate, 0)
    return amount
}


const AddOrIncreaseItemInBasket = (addedItem, get, set, restId) => {
    const basket = get().basket
    let currentBasket = basket[restId]
    const foundItemIndex = currentBasket?.indexOf(addedItem)
    let result = []
    if (foundItemIndex > -1) {
        currentBasket[foundItemIndex]["count"] = currentBasket[foundItemIndex]["count"] + 1
        result = currentBasket
    } else {
        const oldBasket = currentBasket ? [...currentBasket] : []

        result = [...oldBasket, {...addedItem}]
    }

    set({basket: {...basket, [restId]: result}})
}

const RemoveOrDecreaseFromBasket = (item, get, set, restId) => {
    const basket = get().basket
    let currentBasket = basket[restId]
    const index = currentBasket?.indexOf(item)
    if (index >= 0) {
        // item exist in the basket, remove it
        if (currentBasket[index]["count"] <= 1) {
            currentBasket.splice(index, 1)
        } else {
            currentBasket[index]["count"] = currentBasket[index]["count"] - 1
        }
    } else {
        console.warn(
            `Can't remove product{id: ${item.id}} as it is not in the basket`
        );
    }

    set({basket: {...basket, [restId]: currentBasket}})
}

const RemoveItem = (item, get, set, restId) => {
    const basket = get().basket
    let currentBasket = basket[restId]
    if (!currentBasket) {
        return
    }
    const indexOfItemNeedToDelete = currentBasket?.findIndex((basketItem) => basketItem.id === item.id);
    currentBasket.splice(indexOfItemNeedToDelete, 1)
    set({basket: {...basket, [restId]: currentBasket}})
}
