import {memo} from "react";
import {useHistory} from "react-router-dom"
import {useQuery} from "./utils/useQuery";


const Card = memo(({
                       id,
                       card,

                   }) => {

    const history = useHistory()
    let query = useQuery();


    const restID = query.get("rest_id")
    return (

        <div>
            <div
                onClick={() => history.push("gericht/"+id+"?rest_id="+restID)}
                className="w-full flex items-center mb-3  rounded-2xl  bg-gradient-to-r from-blue-50 relative z-10">

                <div className="w-full flex-1 p-3">
                    <h1 className="font-semibold mb-1">{card.name}</h1>
                    <p className="text-gray-500 text-sm mb-2">{card.description}</p>
                    <div className=" rounded-2xl flex font-semibold">
                        <span className="text-gray-500 mr-3">€</span> {card.price.toFixed(2).split(".").join(",")}
                    </div>
                </div>
                {card.img &&
                <div className="rounded-full overflow-hidden h-20 w-20 flex-shrink-0 mr-3 object-cover">
                    <img
                        className="object-cover h-full w-full"
                        src={card.img}
                        alt=""/>
                </div>}
            </div>

        </div>

    )
}, (prev, next) => prev.isSelected === next.isSelected)
export default Card


