import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route} from "react-router-dom"
import ShoppingCard from "./ShpppingCard";
import ExpandedCard from "./ExpandedCard";
import SuccPage from "./SuccPage";

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Route path="/gericht/:id" component={ExpandedCard}/>
            <Route path="/pay" component={ShoppingCard}/>
            <Route path="/succ_order" component={SuccPage}/>
            <Route path="/" component={App}/>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
