import Card from "./Card";

const CardList = ({  history, products }) => {
    return (
        <>
            {
                products.map((card, index) => (
                    <Card
                        history={history}
                        key={card.id}
                        id = {card.id}
                        card={card}
                    />
                ))}
        </>
    )

}


export default CardList
