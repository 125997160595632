import db from "./firebase"
import firebase from "firebase"
import {useEffect, useState} from "react";
import {useFetchedProductsState} from "./GlobalState";


const restaurantID = "i9PZ7Ipb89QDefaX6O2AorqDFAh2"

export const placeOrder = async (order, restID) => {
    try {
        await db.collection("Kunden").doc(restID ||restaurantID).collection("orders").add({...order, orderTimeStamp: firebase.firestore.FieldValue.serverTimestamp()})

    } catch (e) {
        console.log(e)
        return e
    }
}

const fetchProdcuts = async (restID) => {

    let products = []
    const snapshots = await db.collection("Kunden").doc(restID ||restaurantID).collection("Products").get()

    snapshots.forEach(doc => products.push({...doc.data(), id: doc.id, img:doc.data()?.imageURL}))
    console.log(products)
    return products
}



export const fetchStammdaten = async (restID) => {

    const snapshots = await db.collection("Kunden").doc(restID ||restaurantID).get()

    return snapshots.data()
}


const fetchCategories = async (restID) => {

    let cats = []
    const snapshots = await db.collection("Kunden").doc(restID ||restaurantID).collection("Categories").get()

    snapshots.forEach(doc => cats.push({...doc.data(), id: doc.id}))
    return cats
}


export const useProducts = (restID) => {
    const {products, setProducts} = useFetchedProductsState();
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // eslint-disable-next-line
        fetchAsyncProducts()
        fetchAsyncCategories()
        // eslint-disable-next-line
    }, []);

    const fetchAsyncProducts = async () => {
        const products = await fetchProdcuts(restID)
        setProducts(products)
    }

    const fetchAsyncCategories = async () => {
        const cats = await fetchCategories(restID)
        setCategories(cats)
    }

    return [products, categories]
}
