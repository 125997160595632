import firebase from 'firebase'
const firebaseConfig = {
    apiKey: "AIzaSyAZQYO70xs-gNoDipQoiZHylwlINbzk4JM",
    authDomain: "ruslantests.firebaseapp.com",
    databaseURL: "https://ruslantests.firebaseio.com",
    projectId: "ruslantests",
    storageBucket: "ruslantests.appspot.com",
    messagingSenderId: "853046165041",
    appId: "1:853046165041:web:b27d1530e52e6838ef9f10",
    measurementId: "G-ZSGH2G2FE4"
};


let firebaseApp;
// Initialize Firebase if not done jet
if (!firebaseApp) {
    firebaseApp = firebase.initializeApp(firebaseConfig);
}

const db = firebaseApp.firestore()


export default db

